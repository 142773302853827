import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useSearchParams,
} from 'react-router-dom'

import TemplatePage from '@/common/Templates/TemplatePage'
import { PrivateRoute } from '@/components/Security/PrivateRoute'
import { AppContextProvider } from '@/contexts/AppContext'
import Login from '@/pages/Login'
import LoginAccessDenied from '@/pages/Login/AccessDenied'
import Predictions from '@/pages/Panel/Predictions'
import PredictionsResults from '@/pages/Panel/Predictions/Results'
import Sendings from '@/pages/Panel/Sendings'
import { stringToBoolean } from '@/utils/string'

import { UserContextProvider } from './contexts/UserContext'
import LoginDevelopment from './pages/Login/Development'
import Users from './pages/Panel/Users'
import Profile from './pages/Profile'
import UserInfo from './pages/Users'

const RedirectGovBrLogin = () => {
  const [searchParams] = useSearchParams()

  const code = searchParams.get('code')
  const state = searchParams.get('state')

  if (code && state) {
    return <Navigate to={`/login/?code=${code}&state=${state}`} replace />
  }

  return <Outlet />
}

const App = () => {
  const hasDevLogin = stringToBoolean(process.env.REACT_APP_DEV_LOGIN)

  return (
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="login">
            <Route index element={<Login />} />
            {hasDevLogin && (
              <Route path="desenvolvedor" element={<LoginDevelopment />} />
            )}
            <Route path="acesso-negado" element={<LoginAccessDenied />} />
          </Route>

          <Route
            element={
              <UserContextProvider>
                <RedirectGovBrLogin />
              </UserContextProvider>
            }
          >
            <Route path="/" element={<TemplatePage />}>
              <Route path="predicoes">
                <Route index element={<Predictions />} />
                <Route path="resultado" element={<PredictionsResults />} />
              </Route>
              <Route path="envios" element={<Sendings />} />
              <Route path="perfil" element={<Profile />} />
              <Route element={<PrivateRoute allowedProfiles={['Admin']} />}>
                <Route path="usuarios">
                  <Route index element={<Users />} />
                  <Route path="editar/:userId" element={<UserInfo />} />
                </Route>
              </Route>
              <Route index element={<Navigate to="/predicoes" />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
  )
}

export default App
