import React from 'react'

import * as yup from 'yup'

import { Stack } from '@chakra-ui/react'
import {
  Typography,
  Button,
  Form,
  UploadFile,
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@pnld/components-web'

import { useSendingsController } from './controller'

interface ISendDataModalProps {
  isOpen: boolean
  onClose: () => void
}

const SendDataModal = ({ isOpen, onClose }: ISendDataModalProps) => {
  const { formikRef, handleSubmit, sendData } = useSendingsController({
    onClose,
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          <Typography variant="h-xs" color="brand.neutral.dark_2">
            Enviar dados para base de predição
          </Typography>
        </ModalHeader>
        <ModalBody as={Stack} gap={4}>
          <Stack gap={1}>
            <Typography variant="b-sm">
              Para que a predição seja realizada, é necessário fazer o upload do
              arquivo completo de microdados do censo escolar, conforme
              disponibilizado pelo INEP.
            </Typography>
            <Stack direction="row">
              <Button
                label="Baixar arquivo de microdados"
                size="sm"
                variant="secondary"
                border="square"
              />
            </Stack>
          </Stack>
          <Form
            innerRef={formikRef}
            onSubmit={handleSubmit}
            validationSchema={yup.object().shape({
              file: yup.mixed().required('O arquivo é obrigatório'),
            })}
          >
            <UploadFile
              label="Selecione seu arquivo"
              name="file"
              accept=".csv"
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button label="Cancelar" variant="secondary" onClick={onClose} />
          <Button label="Enviar dados" variant="primary" onClick={sendData} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SendDataModal
