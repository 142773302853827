import React, { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { FormikValues } from 'formik'

import { SimpleGrid } from '@chakra-ui/react'
import {
  Stack,
  Flex,
  Typography,
  Button,
  useToast,
  Spinner,
  Select,
  Form,
} from '@pnld/components-web'

import { useQueryGetPredictions } from '@/api/predictions/queries'
import SlidesDisplay from '@/components/Display/Slides'
import TotalizerDisplay from '@/components/Display/Totalizer'
import { getErrorDetails } from '@/utils/error'

import { usePredictionsController } from './controller'
import StagesTable from './Tables/Stages'
import { TStages } from './Tables/types'

const PredictionsResults = () => {
  const {
    predictionsPreview = {
      qtCensus: 0,
      qtH1: 0,
      qtH2: 0,
      qtEntities: 0,
      teachingSteps: {},
    },
    isPredictionsPreviewLoading,
    setPredictionsFilters,
  } = usePredictionsController()
  const toast = useToast()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { filters, spheres, educationStages } = state
  const {
    data: completePredictions = '',
    isLoading: isCompletePredictionsLoading,
    isError: isCompletePredictionsError,
  } = useQueryGetPredictions(
    {
      ...filters,
      typePrediction: 'complete',
    },
    {
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
    }
  )
  const {
    data: summarizedPrediction = '',
    isLoading: isSummarizedPredictionsLoading,
    isError: isSummarizedPredictionsError,
  } = useQueryGetPredictions(
    {
      ...filters,
      typePrediction: 'summarized',
    },
    {
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
    }
  )

  const zoneOptions = [
    { value: 'Rural', label: 'Rural' },
    { value: 'Urbana', label: 'Urbana' },
  ]

  const regionOptions = [
    { value: 'Norte', label: 'Norte' },
    { value: 'Nordeste', label: 'Nordeste' },
    { value: 'Centro-oeste', label: 'Centro-Oeste' },
    { value: 'Sudeste', label: 'Sudeste' },
    { value: 'Sul', label: 'Sul' },
  ]

  const downloadCsv = (str: string, filename: string) => {
    const trimmedData = String(str).trim().slice(1, -1)
    const formattedData = trimmedData.replace(/\\n/g, '\n')
    const url = window.URL.createObjectURL(
      new Blob([formattedData], { type: 'text/csv;charset=utf-8;' })
    )
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${filename}.csv`)
    document.body.appendChild(link)
    link.click()
  }

  const slidesItems = useMemo(() => {
    const teachingStep = predictionsPreview?.teachingSteps
    const items = []
    for (const stage in teachingStep) {
      const subTeachingSteps = teachingStep[stage].subTeachingSteps
      const stepMetrics = {
        total: teachingStep[stage].qtCensus,
        h1: teachingStep[stage].qtH1,
        h2: teachingStep[stage].qtH2,
      }
      const data = []
      for (const subStep in subTeachingSteps) {
        data.push({
          stage: subTeachingSteps[subStep].noEducationStage || '',
          total: subTeachingSteps[subStep].qtCensus,
          h1: subTeachingSteps[subStep].qtH1,
          h2: subTeachingSteps[subStep].qtH2,
        })
      }
      items.push({
        title: 'Etapas de ensino',
        content: (
          <StagesTable
            id={stage as TStages}
            data={data}
            footerData={stepMetrics}
          />
        ),
      })
    }

    return items
  }, [predictionsPreview])

  const handleFilters = (values: FormikValues) => {
    setPredictionsFilters({
      ...filters,
      ...values,
    })
  }

  useEffect(() => {
    setPredictionsFilters(filters)
  }, [])

  return (
    <Stack gap={6}>
      <Stack gap={4} p={4} boxShadow="md" borderRadius="base">
        <Typography variant="h-xs" color="brand.primary.dark_2">
          Predição - {filters.nuYearPrediction}
        </Typography>
        <Flex direction="column">
          <Typography variant="b-sm">
            <strong>Entidades Preditas:</strong>{' '}
            {predictionsPreview?.qtEntities}
          </Typography>
          <Typography variant="b-sm">
            <strong>Esfera:</strong> {spheres}
          </Typography>
          <Typography variant="b-sm">
            <strong>Etapa(s) de ensino:</strong> {educationStages}
          </Typography>
        </Flex>
        <Flex justify="space-between" gap={4}>
          <Button
            label="Voltar"
            variant="tertiary"
            iconName="chevron-left"
            onClick={() => navigate('/predicoes')}
          />
          <Flex gap={4}>
            <Button
              label="Baixar predição completa"
              variant="secondary"
              iconName="file-download"
              isLoading={isCompletePredictionsLoading}
              loadingText="Predição em andamento"
              onClick={() =>
                !isCompletePredictionsError &&
                downloadCsv(completePredictions, 'predicao_completa')
              }
            />
            <Button
              label="Baixar predição resumida"
              iconName="file-download"
              isLoading={isSummarizedPredictionsLoading}
              loadingText="Predição em andamento"
              onClick={() =>
                !isSummarizedPredictionsError &&
                downloadCsv(summarizedPrediction, 'predicao_resumida')
              }
            />
          </Flex>
        </Flex>
      </Stack>
      <Stack gap={4} p={4} boxShadow="md" borderRadius="base">
        <Stack>
          <Typography variant="h-xs" color="brand.primary.dark_2">
            Pré-visualização de dados
          </Typography>
          <Typography variant="b-sm">
            Consulte abaixo totais de dados da predição solicitada.
          </Typography>
        </Stack>
        <Form onSubmit={handleFilters}>
          <Flex gap={4} align="center">
            <Typography variant="b-sm" width="fit-content">
              Filtrar por
            </Typography>
            <Select
              name="dsTpLocation"
              options={zoneOptions}
              placeholder="Zona"
            />
            <Select
              name="noRegion"
              options={regionOptions}
              placeholder="Região"
            />
            <Flex>
              <Button
                type="submit"
                variant="secondary"
                label="Filtrar"
                iconName="fa-filter"
              />
            </Flex>
          </Flex>
        </Form>
        {isPredictionsPreviewLoading ? (
          <Flex justify="center" align="center" gap={4}>
            <Spinner color="brand.primary.dark_2" />
            <Typography variant="h-2xs">Carregando predições...</Typography>
          </Flex>
        ) : (
          <Stack gap={4}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap={4}>
              <TotalizerDisplay
                number={predictionsPreview?.qtCensus}
                label="Censo"
              />
              <TotalizerDisplay number={predictionsPreview?.qtH1} label="H1" />
              <TotalizerDisplay number={predictionsPreview?.qtH2} label="H2" />
              <TotalizerDisplay
                number={predictionsPreview?.qtEntities}
                label="Total de entidades"
                variant="highlight"
              />
            </SimpleGrid>
            <SlidesDisplay items={slidesItems} />
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export default PredictionsResults
